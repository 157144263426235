<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Work Experiences Checklist
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form>

                  <b-form-input
                    v-model="form.form"
                    type="text"
                    class="hidden"
                  />

                  <b-form-input
                    v-model="form.id"
                    type="text"
                    class="hidden"
                  />

                  <b-row>

                    <!-- Input Applicant Name -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Applicant Name"
                        label-for="applicant-name"
                      >
                        <label
                          class="sr-only"
                          for="applicant-name"
                        >
                          * Applicant Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Applicant Name"
                          vid="applicant-name"
                          rules="required"
                        >
                          <b-form-input
                            id="applicant-name"
                            v-model="applicantName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Applicant Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <p>Please check all patient care situations, and/or special care areas in which you have had experience. Please understand that these are not job requirements or responsibilities, but will help us determine where we can best use you.</p>

                  <h4>Companion Care</h4>

                  <!-- Checkbox Companion Care -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="companionCare"
                          v-model="form.companionCare"
                          :options="['Grooming (Hair, Make-up, Shaving)', 'Meal Preparation', 'Shopping/Errands', 'Light Housekeeping', 'Medication Reminder', 'Laundry', 'ADL (Bathing, Continence, Dressing, Eating, Toileting, Transferring)', 'BP/Pulse Monitoring', 'Pet Care']"
                          stacked
                          title="Companion Care"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <h4>Hospice Care</h4>

                  <!-- Checkbox Hospice Care -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="hospiceCare"
                          v-model="form.hospiceCare"
                          :options="['Tracheostomy', 'Respirator', 'Traction', 'Suctioning', 'Colostomy']"
                          stacked
                          title="Hospice Care"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <h4>Mechanical Lifts</h4>

                  <!-- Checkbox Mechanical Lifts -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="mechanicalLifts"
                          v-model="form.mechanicalLifts"
                          :options="['Wheelchair', 'Hoyer']"
                          stacked
                          title="Mechanical Lifts"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <h4>Critical Care</h4>

                  <!-- Checkbox Critical Care -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="criticalCare"
                          v-model="form.criticalCare"
                          :options="['Heart Attack', 'Major Organ Transplant', 'Stroke', 'Blindness', 'Kidney Disease', 'Paralysis', 'COPD', 'ALS/Lou Gehrig’s Disease', 'Parkinson’s Disease', 'Alzheimer/Dementia', 'Heart Surgery', 'Quadriplegic', 'I.V. Monitoring', 'G-Tube Feeding', 'Bed Sores', 'Brain Tumor', 'Risk of Falling', 'Enema', 'Arthritis', 'Paranoia', 'Hip Replacement', 'Knee Replacement', 'Wound Care', 'Ulcer', 'Depression']"
                          stacked
                          title="Critical Care"
                        />
                        <b-form-checkbox
                          ref="criticalCare1"
                          v-model="form.criticalCare1"
                          value="Cancer"
                          title="Critical Care"
                          @change="input = !input"
                        >
                          Cancer
                        </b-form-checkbox>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-form-input
                          ref="criticalCareInput"
                          v-model="form.criticalCareInput"
                          placeholder="Breast, Lung, Colon and Rectum, Prostate, Skin, Stomach etc."
                          :disabled="input"
                          title="Critical Care"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <!-- Input Signature -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Signature"
                        label-for="signature"
                      >
                        <label
                          class="sr-only"
                          for="signature"
                        >
                          * Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="signature"
                            v-model="form.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.signature === '' }"
                            title="Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.signature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Date Signed -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date Signed"
                        vid="date-signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date Signed"
                          label-for="date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-signed"
                          >
                            * Date Signed
                          </label>
                          <flat-pickr
                            id="date-signed"
                            ref="dateSigned"
                            v-model="form.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form3', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form5', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormCheckboxGroup, BButton, VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import Swal from 'sweetalert2'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,

    vSelect,
    flatPickr,
    VueSignaturePad,
    // eslint-disable-next-line
    Swal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const applicantName = ref('')

    const signature = ref(null)

    const input = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          // console.log(jsonObjectLiterals.data)

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          applicantName.value = jsonObjectLiterals.data.firstName + (jsonObjectLiterals.data.middleName ? ' ' + jsonObjectLiterals.data.middleName + ' ' : ' ') + jsonObjectLiterals.data.lastName

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm4', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                if (jsonObjectLiterals.data.signature) {
                  signature.value.fromDataURL(jsonObjectLiterals.data.signature)
                }

                if (jsonObjectLiterals.data.criticalCare1) {
                  input.value = false
                }

                if (form.value.id) {
                  form.value.form = 'folder3_form4'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      // wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'm/d/Y',
      // altInput: true,
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      applicantName,

      signature,

      input,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.signature) {
            self.$refs.signature.clearSignature()
            self.$refs.signature.fromDataURL(jsonObjectLiterals.data.signature)
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : '' */

      this.$refs.simpleRules.validate().then(success => {
        /* this.isNotSaved = true

        store
          .dispatch(this.action === 'add' ? 'app-hr/addForm4' : 'app-hr/updateForm4', this.form)
          .then(response => {
            // eslint-disable-next-line
            const jsonObjectLiterals = response.data.response.data

            this.form.id = jsonObjectLiterals.id

            // console.log(jsonObjectLiterals)

            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved succesfully.',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .finally(() => {
            self.isNotSaved = false
          }) */
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm4' : 'app-hr/updateForm4', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  // console.log(jsonObjectLiterals)

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved succesfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })

          // eslint-disable-next-line
          // alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    clearSignature() {
      this.$refs.signature.clearSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs.Signature.reset()
      }
    },
    undoSignature() {
      this.$refs.signature.undoSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs.Signature.reset()
      }
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
